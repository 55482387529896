export default {
  500: {
    error: 'Ошибка 500',
    title: 'Внутренняя \n' + 'ошибка сервера',
    description: 'На сервере произошла непредвиденная ошибка. Пожалуйста, подождите: она вскоре будет исправлена.'
  },
  404: {
    error: 'Ошибка 404',
    title: 'Страница \nне найдена',
    description: 'Вероятно, страница не существует или \nвы ошиблись, когда вводили' + ' адрес в строке браузера'
  },
  508: 'На сайте ведутся технические работы'
}