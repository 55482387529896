import policy from './policy'
import errors from './errors'

export default {
  policy,
  errors,

  submitApplication: 'Оставить заявку',
  soon: 'Скоро',
  header: {
    services: 'Наши услуги',
    toContactUs: 'Связаться с нами',
  },
  main: {
    title: 'Портал по оплате \n' + 'зарубежных сервисов\n' + 'и товаров',
    description: 'Оплата отелей, онлайн шопинг по всему миру, Netflix, Amazon, Spotify и многое другое…',
    offer: 'Платите на 10% меньше за покупки \nна OZON и Yandex Маркет',
  },
  services: {
    title: 'Наши услуги',
    card: {
      ozon: {
        title: 'Оплата OZON и Yandex \nМаркет с 10% скидкой',
        description: 'Выберите товары на OZON или Yandex Маркет! ' +
          'Пришлите ссылки на них в наш чат, а также контактный номер ' +
          'телефона, адрес и удобное время доставки. Оплатите со своей ' +
          'карты по QR — 90% стоимости выбранных товаров. Спокойно дожидайтесь доставки!'
      },
      crypto: {
        title: 'Покупка / продажа \nкриптовалюты',
        description: 'Мы возьмем на себя все сложности и поможем вам выгодно и ' +
          'максимально безопасно осуществить все операции с криптовалютой. ' +
          'Будь то покупка или продажа биткоина (BTC) или перевод кому-то ' +
          'платежа в USDT. А если вы новичок в криптомире, подскажем, как ' +
          'открыть криптокошелек и поможем разобраться во всех нюансах.'
      },
      virtualCards: {
        title: 'Виртуальные \nкарты',
        description: 'Оплатите со своей российской карты (можно по QR-коду) ' +
          'эквивалент в рублях, портал выпустит для вас зарубежную виртуальную ' +
          'банковскую prepaid-карту, полностью готовую к покупкам. Наша карта ' +
          'подходит для покупки любых товаров и услуг, путешествий, а также оплаты' +
          ' подписок за рубежом. Карту также можно привязать к Google Pay и Apple Pay. ' +
          '\n' +
          'Карта выдается сроком до 30 дней. По вашему требованию мы предоставим баланс ' +
          'или выписку по карте в чате.'
      },
      transferOverseas: {
        title: 'Перевод с русской карты\n' + 'на зарубежную',
        description: 'Оплатите со своей российской карты (можно по QR-коду) ' +
          'эквивалент в рублях, а портал переведет средства на указанную зарубежную карту.'
      },
      payOverseas: {
        title: 'Оплата зарубежных \nсервисов из России',
        description: 'Пришлите ссылку на оплату желаемого товара или услуги ' +
          'в наш чат. Оплатите со своей российской карты (можно по QR-коду) ' +
          'эквивалент в рублях. Портал произведет оплату по данной ссылке за вас.'
      },
      individualRequests: {
        title: 'Индивидуальные\n' + 'запросы',
        description: 'Если у вас нестандартные, сложные финансовые задачи — ' +
          'просто напишите нам и мы найдем решение с максимальной выгодой для вас!'
      },
      transferToRussia: {
        title: 'Переводы средств ' + '\nиз\u{02011}за рубежа в Россию',
        description: 'Оплатите со своей зарубежной карты эквивалент в рублях, а' +
          ' портал переведет деньги на указанную вами российскую банковскую ' +
          'карту или оплатит за вас любой счет в России.'
      },
    },
  },
  telegram: {
    description: 'Чтобы воспользоваться услугами, опишите в Telegram-чате вашу задачу',
    note: '*За свои услуги портал берет небольшую комиссию, ' +
      'в зависимости от выбранной услуги!\n' +
      'Гарантированно вернём деньги, если оплата не пройдет!',
  },
  partners: 'Мы возьмем на себя все сложности, пока вы наслаждаетесь результатом!',
  footer: {
    policy: 'Политика конфиденциальности',
    contactWithUs: 'Вы можете связаться с нами, если у вас есть предложения, жалобы или по любой другой причине: '
  },
  reload: 'Обновить',
  toMainPage: 'Вернуться на главную',
}
