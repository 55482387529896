export default {
  nav: {
    intro: 'Intro',
    infoCollection: 'Information Collection and Use',
    cookies: 'Cookies',
  },
  intro: {
    title: 'Privacy Policy',
    date: 'This policy is effective as of 09 Dec. 2022',
    description: 'This policy intends to inform visitors of this web ' +
      'page regarding our practices related to collection, use, and ' +
      'disclosure of Personal Information, should users decide to ' +
      'take advantage of our Services.'
  },
  cookies: [
    'Although we do not use cookies - files with a small amount of data that ' +
    'are commonly used as anonymous unique identifiers that are  sent to your' +
    ' browser from the websites that you visit and are stored on your device\'s ' +
    'internal memory - there may be scenarios where our Services may use third ' +
    'party code and libraries that use “cookies” to collect information ' +
    'and improve their services.',

    'You will - in any case - have the option to either accept or refuse these ' +
    'cookies and know when a cookie is being sent to your device. If you choose ' +
    'to refuse our cookies, you may not be able to use some portions of this Service.'
  ],
  infoCollection: {
    description: 'If you choose to use our Services, then we may - as required by the ' +
      'specific Service requested - ask you to agree to us collecting and using your ' +
      'personal information. Such information shall be used strictly for providing and ' +
      'improving the Service. We will not use or share your information with anyone except ' +
      'with your express permission, and then only when the service provided requires us to do so.',
  },
}
