import React from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg'
import HeaderNav from './HeaderNav'
import Button from 'components/Button'
import { BOT_URL } from '../../constants'

const cx = classNames.bind(styles)

export type Props = {
  isMenuOpen: boolean
  onMenuClick?: () => void
}

const MobileMenu: React.FC<Props> = ({
  isMenuOpen,
  onMenuClick,
}) => {
  return (
    <div
      className={cx('mobile-menu', isMenuOpen && 'mobile-menu--open')}
    >
      <Button
        text={'submitApplication'}
        icon={<TelegramIcon />}
        size='big'
        className={cx('mobile-menu__button')}
        onClick={() => window.open(BOT_URL, '_self')}
      />

      <HeaderNav
        className={cx('mobile-menu__nav')}
        onMenuClick={onMenuClick}
      />
    </div>
  )
}

export default MobileMenu