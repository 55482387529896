import React from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import PartnerItem from './PartnerItem'
import partnersList from './partnersList'
import InfiniteLooper from './InfiniteLooper'

const cx = classNames.bind(styles)

const Partners: React.FC = () => {
  const [t] = useTranslation()

  return (
    <section className={cx('partners__wrapper')}>
      <h2 className={cx('partners__title')}>
        {t('partners')}
      </h2>

      <div className={cx('list-margin')}>
        <InfiniteLooper direction="right" speed="50">
          {
            partnersList.top.map((name) => {
              return (
                <div key={name} className={cx('lopper-item')}>
                  <PartnerItem name={name} />
                </div>
              )
            })
          }
        </InfiniteLooper>

      </div>

      <InfiniteLooper direction="left" speed="50">
        {
          partnersList.bottom.map((name) => {
            return (
              <div key={name} className={cx('lopper-item')}>
                <PartnerItem name={name} />
              </div>
            )
          })
        }
      </InfiniteLooper>


    </section>
  )
}

export default Partners