import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

const cx = classNames.bind(styles)

type HeaderNavProps = {
  className?: string
  onMenuClick?: () => void
}

type LinkType = 'services' | 'contact-form'

const HeaderNav: React.FC<HeaderNavProps> = ({ className, onMenuClick }) => {
  const [t] = useTranslation()
  const [activeSection, setActiveSection] = useState<LinkType | null>(null)

  const onClick = (e: React.MouseEvent, type: LinkType) => {
    e.preventDefault()
    setActiveSection(type)

    window.scrollTo({
      left: 0,
      top: (document.getElementById(type)?.offsetTop ?? 0) - 200,
      behavior: "smooth",
    })

    onMenuClick && onMenuClick()
  }

  useEffect(() => {
    const services = document.getElementById('services')
    const contactForm = document.getElementById('contact-form')

    const isInView = (el: HTMLElement) => {
      const { top, bottom, height } = el.getBoundingClientRect()
      return (top >= 0 && bottom <= window.innerHeight) || (top < 0 && height > window.innerHeight && bottom >= window.innerHeight)
    }

    const onScroll = () => {
      if (services && isInView(services)) {
        setActiveSection('services')
      } else if (contactForm && isInView(contactForm)) {
        setActiveSection('contact-form')
      } else {
        setActiveSection(null)
      }
    }

    window.addEventListener("scroll", onScroll)
    return () => {
      window.removeEventListener("scroll", onScroll)
    };
  }, [])


  return (
    <nav className={cx('nav', className ? className : '')}>
      <a
        href="/"
        className={cx('nav__link', activeSection === 'services' ? 'nav__link--active' : '')}
        onClick={(e) => onClick(e, 'services')}
        data-section-id='services'
      >
        {t('header.services')}
      </a>
      <a
        href="/"
        className={cx('nav__link', activeSection ==='contact-form' ? 'nav__link--active' : '')}
        onClick={(e) => onClick(e, 'contact-form')}
        data-section-id='contact-form'
      >
        {t('header.toContactUs')}
      </a>
    </nav>
  )
}

export default HeaderNav