import React from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg'
import BackgroundIcons from './BackgroundIcons'
import { BOT_URL } from 'constants/index'

const cx = classNames.bind(styles)

const MainSection: React.FC = () => {
  const [t] = useTranslation()

  return (
    <section className={cx('main-section')}>

      <BackgroundIcons />

      <h1 className={cx('main-section__title')}>
        {t('main.title')}
      </h1>

      <p className={cx('main-section__description')}>
        {t('main.description')}
      </p>

      {/*<div className={cx('main-section__offer')}>*/}
      {/*  {t('main.offer')}*/}
      {/*</div>*/}

      <Button
        className={cx('main-section__button')}
        text={'submitApplication'}
        icon={<TelegramIcon />}
        onClick={() => window.open(BOT_URL, '_self')}
      />
    </section>
  )
}

export default MainSection