import React from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'

const cx = classNames.bind(styles)

const PageError400: React.FC = () => {
  const [t] = useTranslation()

  return (
    <main className={cx('main')}>
      <section>
        <div className={cx('container')}>
          <div className={cx('info')}>
            <p className={cx('error-code')}>
              {t('errors.404.error')}
            </p>
            <h1 className={cx('error-title')}>
              {t('errors.404.title')}
            </h1>
            <p className={cx('error-description')}>
              {t('errors.404.description')}
            </p>

            <Button
              text='toMainPage'
              type='link'
              href='/'
            />
          </div>

          <div className={cx('error-img-container')}>
            <img
              src={require('assets/img/errors/404.png')}
              alt='404 error'
            />
          </div>
        </div>
      </section>
    </main>
  )
}

export default PageError400