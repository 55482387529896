import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ru from './ru'
import en from './en'
import { LOCAL_STORAGE_LNG_KEY } from 'constants/'
import typedKeys from 'helpers/typedKeys'

const resources = {
  ru: {
    translation: ru,
  },
  en: {
    translation: en,
  },
}

/** Language ids */
export const LANGUAGES = typedKeys(resources)

/** Union of CLIENT languages */
export type Languages = typeof LANGUAGES[number]

export const DEFAULT_CLIENT_LNG: Languages = 'ru'
/** Try to get the most appropriative language  */
export const getDefaultLng = () => {
  let language = [window.localStorage.getItem(LOCAL_STORAGE_LNG_KEY)]
    .filter(Boolean)[0]
    ?.slice(0, 2)
    .toLowerCase()

  if (!LANGUAGES.includes(language as any)) {
    window.localStorage.setItem(LOCAL_STORAGE_LNG_KEY, DEFAULT_CLIENT_LNG)
    language = DEFAULT_CLIENT_LNG
  }

  return language
}

i18n.use(initReactI18next).init({
  resources,
  lng: getDefaultLng(),
  interpolation: {
    escapeValue: false,
  },
})
export default i18n

