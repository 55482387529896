import React from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const BackgroundIcons: React.FC = () => {

  return (
    <div className={cx('icon-list')}>
      <img
        /* eslint-disable-next-line @typescript-eslint/no-var-requires */
        src={require('assets/icons/b-coin.png')}
        alt='Binance logo'
        className={cx('icon', 'icon-binance')}
      />

      <img
        /* eslint-disable-next-line @typescript-eslint/no-var-requires */
        src={require('assets/icons/netflix-coin.svg').default}
        alt='Netflix logo'
        className={cx('icon', 'icon-netflix')}
      />

      <img
        /* eslint-disable-next-line @typescript-eslint/no-var-requires */
        src={require('assets/icons/amazon-coin.svg').default}
        alt='Amazon logo'
        className={cx('icon', 'icon-amazon')}
      />

      <img
        /* eslint-disable-next-line @typescript-eslint/no-var-requires */
        src={require('assets/icons/youtube-coin.svg').default}
        alt='Youtube logo'
        className={cx('icon', 'icon-youtube')}
      />

      <img
        /* eslint-disable-next-line @typescript-eslint/no-var-requires */
        src={require('assets/icons/discord-coin.svg').default}
        alt='Discord logo'
        className={cx('icon', 'icon-discord')}
      />

      <img
        /* eslint-disable-next-line @typescript-eslint/no-var-requires */
        src={require('assets/icons/spotify-coin.svg').default}
        alt='Spotify logo'
        className={cx('icon', 'icon-spotify')}
      />
    </div>
  )
}

export default BackgroundIcons