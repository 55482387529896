import policy from './policy'
import errors from './errors'

export default {
  policy,
  errors,

  submitApplication: 'Leave a request',
  soon: 'Soon',
  header: {
    services: 'Our services',
    toContactUs: 'Get in touch with us',
  },
  main: {
    title: 'A payment portal \nfor foreign gods \nand services',
    description: 'Pay for hotels and online purchases anywhere in the world. ' +
      'Pay for Netflix, Amazon, Spotify and many others...',
    offer: 'Pay 10% less for your purchases on OZON and Yandex Market',
  },
  services: {
    title: 'Our services',
    card: {
      ozon: {
        title: '10% discount on purchases \nfrom OZON and Yandex Market',
        description: 'Choose your purchase on OZON or Yandex Market! ' +
          'Send the link to the purchase to our chat along with your contact ' +
          'phone number, address and convenient delivery time. Make a payment ' +
          'from your card using the QR code - 90% of the price of good and wait for the delivery!'
      },
      crypto: {
        title: 'Sell / Buy \nCryptocurrencies',
        description: 'We will take care of all intricacies and will help you ' +
          'with any cruptocurrency operation - in a financially efficient and ' +
          'safe way, be it a purchase of bitcoins (BTC) or a USDT transfer. If' +
          ' you are new to the crypto world we will help you open a reliable wallet ' +
          'and understand the crucial nuances.'
      },
      virtualCards: {
        title: 'Virtual \ncards',
        description: 'Make a payment from your Russian car (QR code payments also possible) ' +
          'the equivalent sum in rubles and the Portal will issue for you a foreign prepaid ' +
          'virtual card, fully ready to make purchases with. Our card works for purchasing ' +
          'any goods, services, travel tickets and foreign service subscriptions. It may be ' +
          'bound to Google Pay and Apple Pay. The card will be issued for the period of up to ' +
          '30 days. If needed, we can provide you with a balance / list of transactions - all in our chat.'
      },
      transferOverseas: {
        title: 'Transfer from a Russian card \nto a Foreign card',
        description: 'Make a payment from your Russian card (QR code payments also possible) in ' +
          'Rubles and the portal will transfer the equivalent in a foreign currency to ' +
          'the specified foreign card.'
      },
      payOverseas: {
        title: 'Pay for foreign services \nfrom Russia',
        description: 'Send a payment link for your desired goods or services to our chat. ' +
          'Pay the equivalent of the price of desired goods or services from your Russian ' +
          'card (QR payment also possible) in rubles. Our Portal will then proceed to pay the link for you.'
      },
      individualRequests: {
        title: 'Individual \nrequests',
        description: 'If you have any non-conventional and complex financial queries, just ' +
          'write to us and we will find a financially effective solution.'
      },
      transferToRussia: {
        title: 'Transfers \nfrom abroad to Russia',
        description: 'Pay for foreign services from Russia'
      },
    },
  },
  telegram: {
    description: 'To use our services, let us know what you wish to do in our telegram chat.',
    note: '* The portal charges a small commission for the services rendered, ' +
      'depending on the service. We guarantee full return of your funds id ' +
      'the payment will not be effected.',
  },
  partners: 'We take care of the complexities, while you enjoy the result!',
  footer: {
    policy: 'Privacy Policy',
    contactWithUs: 'Get in touch if you have any proposals, complaints, or for any other reason: '
  },
  reload: 'Reload',
  toMainPage: 'Return to homepage',
}
