import React from 'react'
import Footer from 'components/Footer'
import Home from 'pages/Home'
import Policy from 'pages/Policy'
import { Routes, Route, useLocation } from 'react-router-dom'
import PageError400 from './pages/Error/404'
import Header from './components/Header'

function App() {
  const location = useLocation()

  const currentPath = location.pathname

  return (
    <div className="App">
      <div id='overlay' />

      {
        currentPath === '/' ? <Header /> : null
      }

      <Routes>
        <Route path="/">
          <Route index element={<Home />} />

          <Route path="policy" element={<Policy />} />

          {/* Using path="*"" means "match anything", so this route
              acts like a catch-all for URLs that we don't have explicit
              routes for. */}
          <Route path="*" element={<PageError400 />} />
        </Route>
      </Routes>

      {
        currentPath === '/' || currentPath === '/policy' ?  <Footer /> : null
      }
    </div>
  );
}

export default App;
