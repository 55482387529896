import React, { ReactNode } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const cx = classNames.bind(styles)

export type Props = {
  /** pass any string to this prop. It value will be translate with i18n */
  text: string

  /** pass any React component */
  icon?: ReactNode

  className?: string

  color?: 'primary' | 'white' | 'grey' | 'blue'

  size?: 'normal' | 'big' | 'small'

  type?: 'button' | 'link'

  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onClick?: () => void

  withShadow?: boolean

  href?: string

  disabled?: boolean
}


const Button: React.FC<Props> = ({
  text,
  icon,
  className,
  color = 'primary',
  size = 'normal',
  onMouseEnter,
  onMouseLeave,
  withShadow = false,
  type = 'button',
  href,
  onClick,
  disabled,
}) => {
  const [t] = useTranslation()

  return (
    type === 'button' ? (
      <button
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={cx(
          'Button',
          `Button--color-${color}`,
          `Button--size-${size}`, withShadow ? 'Button--shadow' : '',
          className ?? ''
        )}
        onClick={onClick}
        disabled={disabled}
      >
        {
          icon && (
            <span className={cx('Button__icon')}>
            {icon}
          </span>
          )
        }
        {t(text)}
      </button>
    ) : (
      <Link to={href || ''} className={cx('link')}>
        {t(text)}
      </Link>
    )
  )
}

export default Button