import React from 'react'
import Button from 'components/Button'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram_in_a_circle.svg'
import { BOT_URL } from 'constants/index'

const cx = classNames.bind(styles)

const Telegram: React.FC = () => {
  const [t] = useTranslation()

  return (
    <section
      id='contact-form'
      className={cx('application-wrapper')}
    >
      <div className={cx('application')}>
        <div className={cx('application__icon')}>
          <TelegramIcon />
        </div>
        <h2 className={cx('application__description')}>
          {t('telegram.description')}
        </h2>

        <Button
          className={cx('application__button')}
          withShadow={true}
          text={'submitApplication'}
          onClick={() => window.open(BOT_URL, '_self')}
        />
      </div>

      <span className={cx('note')}>
        {t('telegram.note')}
      </span>
    </section>
  )
}

export default Telegram