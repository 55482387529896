import React, { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import Button from 'components/Button'
import { useInView } from 'react-intersection-observer'
import { BOT_URL } from 'constants/index'

const cx = classNames.bind(styles)

export type CardProps = {
  /** pass any string to this prop. It value will be translate with i18n */
  title: string

  /** pass any React component */
  icon?: ReactNode

  /** pass any string to this prop. It value will be translate with i18n */
  description: string

  name?: string

  isDisabledBtn?: boolean
}

const Card: React.FC<CardProps> = ({
  title,
  description,
  icon,
  name,
  isDisabledBtn,
}) => {
  const [showHover, setShowHover] = useState(false)
  const [isSelected, setSelected] = useState(false)
  const [t] = useTranslation()
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 1,
  })

  useEffect(() => {
    if (inView) {
      const cardContainer = document.getElementById(`card${name || title}`)
      if (cardContainer?.style) {
        cardContainer.style.opacity = '1'
      }
    }
  }, [inView])

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const elementType = e.target.nodeName
      if (elementType === 'BUTTON') {
        return
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (isSelected && ref.current && !ref.current?.contains(e.target)) {
        setSelected(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isSelected])

  const onCardClick= (e: React.MouseEvent<HTMLDivElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const elementType = e.target.nodeName
    if (elementType === 'BUTTON') {
      return
    } else {
      setSelected(!isSelected)
    }
  }

  return (
    <div
      ref={ref}
      id={`card${name || title}`}
      className={cx('card', showHover ? 'card--hover' : '', isSelected ? 'card--selected' : '')}
      onClick={(e) => onCardClick(e)}
    >
      {icon}

      <div className={cx('card__content')}>
        <h3 className={cx('card__title', showHover ? 'card__title--hover' : '')}>
          {t(title)}
        </h3>

        <p className={cx('card__description', showHover ? 'card__description--hover' : '')}>
          {t(description)}
        </p>

        <Button
          onMouseEnter={() => setShowHover(true)}
          onMouseLeave={() => setShowHover(false)}
          text={isDisabledBtn ? 'soon' : 'submitApplication'}
          color={showHover ? 'primary' : 'white'}
          size={'big'}
          className={cx('card__button', isSelected ? 'card__button--selected' : '', isDisabledBtn ? 'card__button--disabled' : '')}
          onClick={() => !isDisabledBtn ? window.open(BOT_URL, '_self') : {}}
          disabled={isDisabledBtn}
        />
      </div>
    </div>
  )
}

export default Card