import React from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ID_LIST } from './index'

const cx = classNames.bind(styles)

export type NavValue = 'intro' | 'infoCollection' | 'logData' | 'cookies' | 'serviceProviders' | 'security' | 'links' | 'childrenPrivacy' | 'changes' | 'contactUs'

const scrollInto = (
  e: React.MouseEvent,
  id: string,
  offset = 0,
) => {
  e.preventDefault()

  window.scrollTo({
    left: 0,
    top: (document.getElementById(id)?.offsetTop ?? 0) + offset,
    behavior: "smooth",
  })
}

type NavigationProps = {
  activeSection: string
  setActiveSection: (v: string) => void
}

const Navigation: React.FC<NavigationProps> = ({
  activeSection,
  setActiveSection,
}) => {
  const [t] = useTranslation()

  const NAV = [
    { id: ID_LIST.intro, value: 'policy.nav.intro' },
    { id: ID_LIST.info, value: 'policy.nav.infoCollection' },
    { id: ID_LIST.cookies, value: 'policy.nav.cookies' },
  ]

  return (
    <aside className={cx('aside')}>
      <Link to="/" className={cx('aside-logo-link')}>
        <img
          /* eslint-disable-next-line @typescript-eslint/no-var-requires */
          src={require('assets/img/logo.svg').default}
          alt='Logo'
          className={cx('logo')}
        />
      </Link>

      <nav className={cx('aside-nav')}>
        {NAV.map(({ value, id }) => {
          return (
            <a
              key={id}
              href="/"
              data-section-id={id}
              onClick={(e) => {
                scrollInto(e, id, -150)
                setTimeout(
                  () => setActiveSection(id),
                  10
                );
              }}
              className={cx('nav-link', activeSection === id ? 'nav-link--active' : '')}
            >
              {t(value)}
            </a>
          );
        })}
      </nav>
    </aside>
  )
}

export default Navigation
