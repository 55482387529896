import React, {useEffect, useState} from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import Card from 'components/Card'

const cx = classNames.bind(styles)

const Services: React.FC = () => {
  const [t] = useTranslation()
  const [currentWidth, setWidth] = useState<number>(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const isMobile = currentWidth < 576

  return (
    <section
      id='services'
      className={cx('service-container')}
    >
      <h2 className={cx('service-title')}>
        {t('services.title')}
      </h2>

      <div className={cx('service-list--desktop')}>
        <div className={cx('service-list__right')}>
          <Card
            icon={(
              <img
                src={require('assets/img/wallet.png')}
                alt='Wallet'
                className={cx('card__img')}
              />
            )}
            title={'services.card.payOverseas.title'}
            description={'services.card.payOverseas.description'}
          />

          <Card
            icon={(
              <img
                src={require('assets/img/mir.png')}
                alt='Credit card mir'
                className={cx('card__img')}
              />
            )}
            title={'services.card.transferToRussia.title'}
            description={'services.card.transferToRussia.description'}
            isDisabledBtn={true}
          />

          <Card
            icon={(
              <img
                src={require('assets/img/cards.png')}
                alt='Credit card'
                className={cx('card__img')}
              />
            )}
            title={'services.card.virtualCards.title'}
            description={'services.card.virtualCards.description'}
          />

        </div>

        <div>
          <Card
            icon={(
              <img
                src={require('assets/img/global.png')}
                alt='Image of earth with dollar'
                className={cx('card__img')}
              />
            )}
            title={'services.card.transferOverseas.title'}
            description={'services.card.transferOverseas.description'}
          />

          <Card
            icon={(
              <img
                src={require('assets/img/crypto.png')}
                alt='Cryptocurrencies image'
                className={cx('card__img')}
              />
            )}
            title={'services.card.crypto.title'}
            description={'services.card.crypto.description'}
          />

          <Card
            icon={(
              <img
                src={require('assets/img/individual-requests.png')}
                alt='Cash'
                className={cx('card__img')}
              />
            )}
            title={'services.card.individualRequests.title'}
            description={'services.card.individualRequests.description'}
          />

          <Card
            icon={(
              <img
                src={require('assets/img/ozon.png')}
                alt='Ozon logo'
                className={cx('card__img')}
              />
            )}
            title={'services.card.ozon.title'}
            description={'services.card.ozon.description'}
            isDisabledBtn={true}
          />
        </div>
      </div>

      <div className={cx('service-list--mobile')}>
        <Card
          icon={(
            <div>
              <img
                src={isMobile ? require('assets/img/wallet-mobile.png') : require('assets/img/wallet-tablet.png')}
                alt='Wallet'
                className={cx('card__img')}
              />
            </div>
          )}
          title={'services.card.payOverseas.title'}
          description={'services.card.payOverseas.description'}
          name={'mobile.services.card.payOverseas.description'}
        />

        <Card
          icon={(
            <img
              src={isMobile ? require('assets/img/global-mobile.png') : require('assets/img/global-tablet.png')}
              alt='Image of earth with dollar'
              className={cx('card__img')}
            />
          )}
          title={'services.card.transferOverseas.title'}
          description={'services.card.transferOverseas.description'}
          name={'mobile.services.card.transferOverseas.description'}
        />

        <Card
          icon={(
            <img
              src={isMobile ? require('assets/img/mir.png') : require('assets/img/mir-tablet.png')}
              alt='Credit card mir'
              className={cx('card__img')}
            />
          )}
          title={'services.card.transferToRussia.title'}
          description={'services.card.transferToRussia.description'}
          name={'mobile.services.card.transferToRussia.description'}
          isDisabledBtn={true}
        />

        <Card
          icon={(
            <img
              src={isMobile ? require('assets/img/cards-mobile.png') : require('assets/img/cards-tablet.png')}
              alt='Credit card'
              className={cx('card__img')}
            />
          )}
          title={'services.card.virtualCards.title'}
          description={'services.card.virtualCards.description'}
          name={'mobile.services.card.virtualCards.description'}
        />

        <Card
          icon={(
            <div>
              <img
                src={isMobile ? require('assets/img/crypto-mobile.png') : require('assets/img/crypto-tablet.png')}
                alt='Cryptocurrencies image'
                className={cx('card__img')}
              />
            </div>
          )}
          title={'services.card.crypto.title'}
          description={'services.card.crypto.description'}
          name={'mobile.services.card.crypto.description'}
        />

        <Card
          icon={(
            <img
              src={isMobile
                ? require('assets/img/individual-requests-mobile.png')
                : require('assets/img/individual-requests-tablet.png')
              }
              alt='Cash'
              className={cx('card__img')}
            />
          )}
          title={'services.card.individualRequests.title'}
          description={'services.card.individualRequests.description'}
          name={'mobile.services.card.individualRequests.description'}
        />

        <Card
          icon={(
            <img
              src={isMobile ? require('assets/img/ozon-mobile.png') : require('assets/img/ozon-tablet.png')}
              alt='Ozon logo'
              className={cx('card__img')}
            />
          )}
          title={'services.card.ozon.title'}
          description={'services.card.ozon.description'}
          name={'mobile.services.card.ozon.description'}
          isDisabledBtn={true}
        />
      </div>
    </section>
  )
}

export default Services