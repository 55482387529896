import React, { useEffect } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import MainSection from './components/MainSection'
import Services from './components/Services'
import Telegram from './components/Telegram'
import Partners from './components/Partners'

const cx = classNames.bind(styles)

const HomePage: React.FC = () => {

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
    })
  }, [])

  return (
    <main>
      <div className={cx('container')}>
        <MainSection />

        <Services />

        <Telegram />

        <Partners />
      </div>
    </main>
  )
}

export default HomePage
