import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import Navigation from './Navigation'
import { Link } from 'react-router-dom'

const cx = classNames.bind(styles)

export const ID_LIST = {
  intro: "intro",
  info: "info",
  cookies: "cookies",
}

const PolicyPage: React.FC = () => {
  const [t] = useTranslation()
  const [activeSection, setActiveSection] = useState(ID_LIST.intro)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    })

    const intro = document.getElementById(ID_LIST.intro)
    const info = document.getElementById(ID_LIST.info)
    const cookies = document.getElementById(ID_LIST.cookies)

    const isInView = (el: HTMLElement) => {
      const { top, bottom } = el.getBoundingClientRect();
      return top >= 0 && bottom <= window.innerHeight;
    };

    const onScroll = () => {
      if (intro && isInView(intro)) {
        setActiveSection(ID_LIST.intro);
      } else if (info && isInView(info)) {
        setActiveSection(ID_LIST.info);
      } else if (cookies && isInView(cookies)) {
        setActiveSection(ID_LIST.cookies);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll)
    };
  }, []);

  useEffect(() => {
    const targetLink = document.querySelector<HTMLAnchorElement>(
      `[data-section-id=${activeSection}]`
    );

    targetLink?.parentElement?.scrollTo({
      top: 0,
      left: targetLink?.offsetLeft - 16,
      behavior: "smooth",
    });
  }, [activeSection])

  return (
    <>
      <header className={cx('header')}>
        <Link to="/">
          <img
            /* eslint-disable-next-line @typescript-eslint/no-var-requires */
            src={require('assets/img/logo.svg').default}
            alt='Logo'
            className={cx('logo')}
          />
        </Link>
      </header>

      <main className={cx('policy-container')}>
        <Navigation
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        />

        <div className={cx('policy-content')}>
          <section id={ID_LIST.intro}>
            <h1>{t('policy.intro.title')}</h1>
            <span>{t('policy.intro.date')}</span>
            <p>
              {t('policy.intro.description')}
            </p>
          </section>

          <section id={ID_LIST.info}>
            <h2>{t('policy.nav.infoCollection')}</h2>
            <p>
              {t('policy.infoCollection.description')}
            </p>
          </section>

          <section id={ID_LIST.cookies}>
            <h2>{t('policy.nav.cookies')}</h2>
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              t('policy.cookies', { returnObjects: true })?.map((translation, index) => {
                return (
                  <p key={index}>
                    {translation}
                  </p>
                )
              })
            }
          </section>
        </div>
      </main>
    </>
  )
}

export default PolicyPage
