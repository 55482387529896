export default {
  nav: {
    intro: 'Политика конфиденциальности',
    infoCollection: 'Использование персональной информации',
    cookies: 'Куки',
  },
  intro: {
    title: 'Политика конфиденциальности',
    date: 'Данная политика действительна с 08 Декабря 2022 года\n',
    description: 'Настоящая политика созадана для того, чтобы проинформировать' +
      ' посетителей нашей страницы о том, как мы собираем, используем и ' +
      'раскрываем Персональные Данные в случае если посетители принимают ' +
      'решение воспользоваться нашими Услугами. '
  },
  cookies: [
    'Несмотря на то, что мы не используем куки - файлы с небольшим количеством ' +
    'данных, которые обычно используются как уникальные анонимные идентификаторы, ' +
    'направляемые в ваш браузер с веб-страниц, которые вы посещаете, и которые ' +
    'хранятся во внутренней памяти вашего устройства - возможны случаи, когда ' +
    'наши Услуги используют код третьих лиц и библиотеки, которые могут ' +
    'использовать куки для сбора информации и улучшения их сервисов.\n',

    'У вас в любом случае будет возможность принять данные куки или отказаться от них. ' +
    ' В\u{2009} случае отказа от куки, доступ к некоторым разделам сервиса может быть ограничен. '
  ],
  infoCollection: {
    description: 'Если вы решите воспользоваться нашими Услугами, мы можем - в соответствии ' +
      'с требованиями конкретной Услуги - попросить вас согласиться на сбор и ' +
      'использование вашей персональной информации. Данная информация будет ' +
      'использоваться строго для оказания Услуги и совершенствования нашего сервиса. ' +
      'Мы не будем разглашать вашу персональную информацию третьим лицам, кроме как в ' +
      'случаях когда этого требует оказываемая Услуга и при условии вашего прямого ' +
      'согласия на такое раскрытие. ',
  },
}
