export default {
  top: [
    'kalyna',
    'paycash',
    'malinka',
    'bitcoin',
    'tether',
    'youtube',
    'netflix',
    'visa',
    'mastercard',
    'discord',
    'ozon',
    'binance',
  ],

  bottom: [
    'amazon',
    'spotify',
    'figma',
    'kraken',
    'ebay',
    'farfetch',
    'microsoft',
    'apple',
    'google-play',
    'mir',
    'yandex',
    'kucoin',
  ]
}