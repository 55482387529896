import React, {useEffect, useState} from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

type PartnerItemProps = {
  name: string
}

const PartnerItem: React.FC<PartnerItemProps> = ({ name }) => {
  const [state, setState] = useState()

  useEffect(() => {
    const fetchImage = async () => {
      const response = await import(`assets/icons/${name}.svg`)
      setState(response.default)
    }

    fetchImage()
  }, [name])

  return (
    <div className={cx('partners__item')}>
      <img
        src={state}
        alt={name}
        className={cx('item__img')}
      />
    </div>
  )
}

export default PartnerItem