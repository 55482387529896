import React, { useRef, useState, useEffect} from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg'
import { BOT_URL, LOCAL_STORAGE_LNG_KEY } from 'constants/index'
import HeaderNav from './HeaderNav'
import MobileMenu from './MobileMenu'

const cx = classNames.bind(styles)

const LANGUAGES = [
  { code: 'ru', label: 'RU' },
  { code: 'en', label: 'EN' },
]

const Header: React.FC = () => {
  const [, i18n] = useTranslation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const anotherLanguage = LANGUAGES.find(({ code }) => code !== i18n.language)
  const headerRef = useRef()

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (isMenuOpen && headerRef.current && !headerRef.current?.contains(e.target)) {
        onMenuClick()
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isMenuOpen])

  const changeLanguage = () => {
    const lng = anotherLanguage?.code || 'ru'
    localStorage.setItem(LOCAL_STORAGE_LNG_KEY, lng)
    i18n.changeLanguage(lng)
  }

  const onMenuClick = () => {
    const elemt = document.getElementById('overlay')
    if (elemt && !isMenuOpen) {
      elemt.classList.add('overlay')
      document.body.style.overflow = 'hidden'
    }
    if (elemt && isMenuOpen) {
      elemt.classList.remove('overlay')
      document.body.style.overflow = 'unset'
    }
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <header
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={headerRef}
      className={cx('container')}
    >
      <div className={cx('header', isMenuOpen && 'header--mobile')}>
        <div className={cx('header__logo-area')}>
          <button
            className={cx('mobile-menu__control')}
            onClick={onMenuClick}
          >
            <span className={cx('control__icon', isMenuOpen && 'control__icon--open')} />
          </button>

          <MobileMenu isMenuOpen={isMenuOpen} onMenuClick={onMenuClick} />

          <a href="/" className={cx('header__logo-link')}>
            <img
              /* eslint-disable-next-line @typescript-eslint/no-var-requires */
              src={require('assets/img/logo.svg').default}
              alt='Logo'
              className={cx('logo')}
            />
          </a>
        </div>

        <HeaderNav />

        <div className={cx('header__buttons-wrapper')}>
          <button
            className={cx('language-btn')}
            onClick={changeLanguage}
          >
            {anotherLanguage?.code?.toUpperCase()}
          </button>

          <Button
            className={cx('header__application-btn')}
            text={'submitApplication'}
            icon={<TelegramIcon />}
            size='small'
            onClick={() => window.open(BOT_URL, '_self')}
          />
        </div>
      </div>
    </header>
  )
}

export default Header

