import React from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {ReactComponent as TelegramIcon} from 'assets/icons/telegram.svg'
import Button from 'components/Button'
import { BOT_URL } from 'constants/index'

const cx = classNames.bind(styles)

const Footer: React.FC = () => {
  const [t] = useTranslation()

  return (
    <footer className={cx('footer')}>
      <div>
        <Link to="/">
          <img
            /* eslint-disable-next-line @typescript-eslint/no-var-requires */
            src={require('assets/img/logo.svg').default}
            alt='Logo'
            className={cx('footer__logo')}
          />
        </Link>

        <Link to='policy' className={cx('footer__link', 'footer__link--margin')}>
          {t('footer.policy')}
        </Link>
      </div>

      <div className={cx('footer__email')}>
        {t('footer.contactWithUs')}
        <a href="mailto:info@payportal.cash" className={cx('footer__link')}>
          info@payportal.cash
        </a>
      </div>

      <Button
        size='big'
        className={cx('footer__application-btn')}
        text={'submitApplication'}
        icon={<TelegramIcon />}
        onClick={() => window.open(BOT_URL, '_self')}
      />
    </footer>
  )
}

export default Footer

